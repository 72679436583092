<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div>
            <el-button type="primary"
                       size="mini"
                       @click="readMessage"
                       v-if="$authCheck('1_Message_ReadMessage')">{{'设为已读'}}</el-button>
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1" style="height:750px">
            <ex-search-table-pagination ref="searchTable"
                                        :fetch="$client.queryCurrentUserMessage"
                                        :columns="columns"
                                        @selection-change="selectHandle"
                                        :formOptions="formOptions">
                <template slot="statusNameSloat" slot-scope="props">
                    <span style="color:red;" v-if="props.row.status==='unread'">{{props.row.statusName}}</span>
                    <span v-else>{{props.row.statusName}}</span>
                </template>
                <template slot="contentSloat" slot-scope="props">
                    <div v-html="props.row.content"></div>
                </template>
            </ex-search-table-pagination>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MessageCenter',
    data() {
        return {
            selectedRows: [],
            messageStatusList: [],
            messageTypeList: []
        };
    },
    methods: {
        selectHandle(val) {
            this.selectedRows = val;
        },
        readMessage() {
            const {selectedRows} = this;
            const ids = selectedRows.map(item => item.messageId);
            if (ids.length < 1) {
                this.$message({
                    message: '请先选择待操作数据',
                    type: 'warning'
                });
            } else {
                this.$client.readMessage(ids).then(() => {
                    this.$message({
                        message: '操作成功！',
                        type: 'success'
                    });
                    this.$refs.searchTable.searchHandler();
                })
                    .catch(() => {
                        this.$message({
                            message: '操作失败！',
                            type: 'error'
                        });
                    });
            }
        }
    },
    computed: {
        columns() {
            return [
                {
                    type: 'selection'
                },
                {
                    prop: 'title',
                    label: '标题',
                    width: 200
                },
                {
                    prop: 'content',
                    label: '正文',
                    slotName: 'contentSloat'
                },
                {
                    prop: 'typeName',
                    label: '消息类别',
                    width: 150
                },
                {
                    prop: 'statusName',
                    label: '读取状态',
                    slotName: 'statusNameSloat',
                    width: 120
                },
                {
                    prop: 'sendTime',
                    label: '推送时间',
                    width: 150
                }
            ];
        },
        formOptions() {
            return {
                rules: {},
                forms: [
                    {
                        prop: 'keyword',
                        label: '查询关键字',
                        placeholder: '请输入查询关键字',
                    },
                    {
                        prop: 'type',
                        itemType: 'select',
                        clearable: true,
                        label: '消息类别',
                        placeholder: '请输入消息类别',
                        options: this.messageTypeList
                    },
                    {
                        prop: 'status',
                        itemType: 'select',
                        clearable: true,
                        label: '是否已读',
                        placeholder: '请输入是否已读',
                        options: this.messageStatusList
                    }
                ]
            };
        }
    },
    created() {
        this.$enum('EMessageType').then(data => {
            this.messageTypeList = data;
        });
        this.$enum('EMessageStatus').then(data => {
            this.messageStatusList = data;
        });
    }
};
</script>
