<template>
    <div class="calendar-container" :style="{width: root.dailySpace  * 7+ 'px'}">
        <div class="calendar-header">
            <div
                class="calendar-header__day"
                v-for="index in 7"
                :key="`header-cell--${index}`"
                :style="{width: root.dailySpace + 'px'}"
            >
                {{$t(mapping[index-1])}}
                <div class="day-devide">
                    <div
                        class="day-devide__item"
                        v-for="devide in (root.splitDay / 2)"
                        :key="devide"
                    >
                        <div class="day-devide__text">{{devide * root.splitDay}}</div>
                        <div class="day-devide__flag"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="calendar-body">
            <div
                v-for="(item, index) in calendarArrWidthMission || []"
                class="calendar-body__row"
                :key="`body-row--${index}`"
            >
                <div
                    :style="{width: root.dailySpace + 'px'}"
                    v-for="(day, index) in item.days"
                    :class="[day.type !== 'current' ? 'calendar-body__day--gray' : '', day.date === $dayjs().format('YYYY-MM-DD') ? 'calendar-body__day--current' : '','calendar-body__day']"
                    :key="`day--${index}`"
                >{{$dayjs(day.date).date()}}</div>
                <calendar-mission :mission="item.missions || {}" :date="item.days[0].date"></calendar-mission>
            </div>
        </div>
    </div>
</template>
<script>
import CalendarMission from './calendarMission';
export default {
    name: 'CalendarTable',
    components: {
        CalendarMission
    },
    props: {
        calendarArrWidthMission: {
            type: Array
        }
    },
    inject: ['root'],
    computed: {
        mapping() {
            return {
                0: 'common.sun',
                1: 'common.mon',
                2: 'common.tues',
                3: 'common.wed',
                4: 'common.thur',
                5: 'common.fri',
                6: 'common.sat'
            };
        }
    }
};
</script>
<style lang="less" scoped>
.calendar-container {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    .calendar-header {
        display: flex;
        flex-wrap: nowrap;
        font-size: 14px;
        &__day {
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            text-align: center;
            flex-shrink: 0;
            box-sizing: border-box;
        }
    }
    .calendar-body {
        &__row {
            display: flex;
            flex-direction: row;
            position: relative;
            overflow: hidden;
        }

        &__day {
            border-right: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            height: 100px;
            font-size: 14px;
            text-align: center;
            &--gray {
                color: #ccc;
                user-select: none;
            }
            &--current {
                background-color: #17698e;
                color: #00ffff;
                font-weight: 700;
            }
        }
    }
}
.calendar-header__day {
    height: 60px;
    display: flex;
    flex-direction: column;
    .day-devide {
        margin-top: auto;
        background: #fff3a7;
        font-size: 12px;
        display: flex;
        justify-content: space-evenly;
        &__flag {
            width: 1px;
            height: 6px;
            background: #ccc;
            margin: 0 auto;
        }
        &__text {
            text-align: center;
            margin: 0 auto;
        }
    }
}
</style>
