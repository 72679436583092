<template>
    <div>
        <div class="ui-layout-content--narrow ui-layout-flex">
            <el-form class="base-info__form ui-layout-flex--1"
                     :model="formData"
                     size="mini"
                     :inline="true"
                     ref="form"
                     label-position="top"
                     :disabled="true"
                     style="width:90%">
                <el-card class="box-card" :style="{margin: '0 0 10px 0'}">
                    <div slot="header" class="clearfix">
                        <span>{{'基本信息'}}</span>
                    </div>
                    <div class="text item">
                        <el-row :gutter="24">
                            <el-col :span="8">
                                <el-form-item prop="id"
                                              :label="'姓名'">
                                    <user-select @change="getUserJobNumber"
                                                 v-model="formData.id"
                                                 :multiple="false"
                                                 :disabled="id!=null && id!=''"></user-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="jobNumber"
                                              :label="'工号'">
                                    <el-input v-model="formData.jobNumber" :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="sex"
                                              :label="'性别'">
                                    <el-select v-model="formData.sex"
                                               :placeholder="null">
                                        <el-option v-for="item in keyValues.sex"
                                                   :key="item.key"
                                                   :value="item.key"
                                                   :placeholder="null"
                                                   :label="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="nation"
                                              :label="'民族'">
                                    <el-select v-model="formData.nation"
                                               filterable
                                               clearable
                                               :placeholder="null">
                                        <el-option v-for="item in keyValues.nation"
                                                   :key="item.key"
                                                   :value="item.key"
                                                   :label="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="birthday"
                                              :label="'出生日期'"
                                              style="width:100%">
                                    <el-date-picker v-model="formData.birthday"
                                                    type="date"
                                                    value-format="yyyy-MM-dd"
                                                    style="width:80%"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="countryId"
                                              :label="'国籍'">
                                    <el-select filterable
                                               clearable
                                               :placeholder="null"
                                               v-model="formData.countryId"
                                               @change="getProvince">
                                        <el-option v-for="item in keyValues.country"
                                                   :key="item.key"
                                                   :value="item.key"
                                                   :label="item.value"
                                                   :disabled="item.disabled"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="birthplace"
                                              :label="'出生地'">
                                    <el-select filterable
                                               clearable
                                               v-model="formData.birthplace"
                                               :placeholder="null">
                                        <el-option v-for="item in province"
                                                   :key="item.key"
                                                   :value="item.key"
                                                   :label="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="enterCompanyOn"
                                              :label="'入职日期'"
                                              style="width:100%">
                                    <el-date-picker v-model="formData.enterCompanyOn"
                                                    style="width:80%"
                                                    type="date"
                                                    value-format="yyyy-MM-dd"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="position"
                                              :label="'职务'">
                                    <el-select filterable
                                               clearable
                                               v-model="formData.position"
                                               :placeholder="null">
                                        <el-option v-for="item in keyValues.position"
                                                   :key="item.key"
                                                   :value="item.key"
                                                   :label="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="team"
                                              :label="'所属机队'"
                                              v-if="kind === 'fly'||kind === 'attendant'||kind === 'secure'||kind === 'othercompanies'">
                                    <el-select filterable
                                               clearable
                                               v-model="formData.team"
                                               :placeholder="null">
                                        <el-option v-for="item in keyValues.team"
                                                   :key="item.key"
                                                   :value="item.key"
                                                   :label="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item prop="center"
                                              :label="'所属中心'"
                                              v-if="kind === 'dispatch'||kind === 'maintain' ">
                                    <el-select filterable
                                               clearable
                                               v-model="formData.center"
                                               :placeholder="null"
                                               style="width: 80%">
                                        <el-option v-for="item in keyValues.center"
                                                   :key="item.key"
                                                   :value="item.key"
                                                   :label="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="phone"
                                              :label="'移动电话'">
                                    <el-input v-model="formData.phone"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="base"
                                              :label="'所属基地'">
                                    <el-select filterable
                                               clearable
                                               v-model="formData.base"
                                               :placeholder="null">
                                        <el-option v-for="item in keyValues.base"
                                                   :key="item.key"
                                                   :value="item.key"
                                                   :label="item.value"
                                                   :disabled="item.disabled"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="16">
                                <el-form-item prop="contactAddress"
                                              :label="'通讯地址'"
                                              style="width: 100%">
                                    <el-input v-model="formData.contactAddress" style="width: 90%"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="postcode"
                                              :label="'邮政编码'">
                                    <el-input v-model="formData.postcode"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="16">
                                <el-form-item prop="residentialAddress"
                                              :label="'居住地址'"
                                              style="width: 100%">
                                    <el-input v-model="formData.residentialAddress"
                                              style="width: 90%"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="domesticRegion"
                                              :label="'国内地区'">
                                    <el-select filterable
                                               clearable
                                               v-model="formData.domesticRegion"
                                               :placeholder="null">
                                        <el-option v-for="item in keyValues.domesticRegion"
                                                   :key="item.key"
                                                   :value="item.key"
                                                   :label="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </el-card>
                <el-card class="box-card" :style="{margin: '0 0 10px 0'}">
                    <div slot="header" class="clearfix">
                        <span>{{'证照信息'}}</span>
                    </div>
                    <div class="text item">
                        <template>
                            <el-table :data="LicenceFormData" style="width: 100%">
                                <el-table-column prop="credentialName"
                                                 :label="'证照名称'"></el-table-column>
                                <el-table-column prop="credentialNo"
                                                 :label=" '证照号'"></el-table-column>
                                <el-table-column prop="planeTypeName"
                                                 :label="'机型'"></el-table-column>
                                <el-table-column prop="level"
                                                 :label="'证照等级'"></el-table-column>
                                <el-table-column prop="getTime"
                                                 :label="'颁发日期'"
                                                 :formatter="dateFormat"></el-table-column>
                                <el-table-column prop="expireTime"
                                                 :label="'失效日期'"
                                                 :formatter="dateFormat"></el-table-column>
                                <el-table-column prop="statusName"
                                                 :label="'状态'"></el-table-column>
                            </el-table>
                        </template>
                    </div>
                </el-card>
                <el-card class="box-card" :style="{margin: '0 0 10px 0'}">
                    <div slot="header" class="clearfix">
                        <span>{{'运行资质'}}</span>
                    </div>
                    <div class="text item">
                        <template>
                            <el-table :data="GualificationsFormData" style="width: 100%">
                                <el-table-column prop="credentialName"
                                                 :label="'资质名称'"></el-table-column>
                                <el-table-column prop="planeTypeName"
                                                 :label=" '机型'"></el-table-column>
                                <el-table-column prop="airport"
                                                 :label="'机场'"></el-table-column>
                                <el-table-column prop="route"
                                                 :label="'航线'"></el-table-column>
                                <el-table-column prop="getTime"
                                                 :label="'颁发日期'"
                                                 :formatter="dateFormat"></el-table-column>
                                <el-table-column prop="expireTime"
                                                 :label="'失效日期'"
                                                 :formatter="dateFormat"></el-table-column>
                                <el-table-column prop="isValidTxt"
                                                 :label="'是否有效'"></el-table-column>
                                <el-table-column prop="statusName"
                                                 :label="'状态'"></el-table-column>
                            </el-table>
                        </template>
                    </div>
                </el-card>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>{{'技术等级'}}</span>
                    </div>
                    <div class="text item">
                        <template>
                            <el-table :data="TechnicalGradeFormData" style="width: 100%">
                                <el-table-column prop="credentialName"
                                                 :label="'技术等级名称'"></el-table-column>
                                <el-table-column prop="planeTypeName"
                                                 :label="'机型'"></el-table-column>
                                <el-table-column prop="getTime"
                                                 :label="'颁发日期'"
                                                 :formatter="dateFormat"></el-table-column>
                                <el-table-column prop="expireTime"
                                                 :label="'失效日期'"
                                                 :formatter="dateFormat"></el-table-column>
                                <el-table-column prop="statusName"
                                                 :label="'状态'"></el-table-column>
                                <el-table-column prop="isValidTxt"
                                                 :label="'是否有效'"></el-table-column>
                            </el-table>
                        </template>
                    </div>
                </el-card>
                <el-card class="box-card" v-if="kind === 'maintain'">
                    <div slot="header" class="clearfix">
                        <span>{{'授权岗位'}}</span>
                    </div>
                    <div class="text item">
                        <template>
                            <el-table :data="TypePostFormData" style="width: 100%">
                                <el-table-column prop="authorizationName"
                                                 width=170px
                                                 :label="'授权岗位'"></el-table-column>
                                <el-table-column prop="authorizationNo"
                                                 :label=" '授权编号'"></el-table-column>
                                <el-table-column prop="projectName"
                                                 width=90px
                                                 :label="'授权项目'"></el-table-column>
                                <el-table-column prop="awardTime"
                                                 width=100px
                                                 :label="'颁发日期'"
                                                 :formatter="dateFormat"></el-table-column>
                                <el-table-column prop="effectiveTime"
                                                 width=100px
                                                 :label="'失效日期'"
                                                 :formatter="dateFormat"></el-table-column>
                                <el-table-column prop="remainingDate"
                                                 width=80px
                                                 :label="'剩余天数'"></el-table-column>
                                <el-table-column prop="statusName"
                                                 width=80px
                                                 :label="'状态'">
                                    <template slot-scope="scope">
                                        <span style="color:red;" v-if="scope.row.status==='remind'||scope.row.status==='newer'">{{scope.row.statusName}}</span>
                                        <span v-else>{{scope.row.statusName}}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                </el-card>
                <el-card class="box-card" v-if="kind === 'maintain'">
                    <div slot="header" class="clearfix">
                        <span>{{'授权项目'}}</span>
                    </div>
                    <div class="text item">
                        <template>
                            <el-table :data="TypeProjectFormData" style="width: 100%">
                                <el-table-column prop="authorizationName"
                                                 width=170px
                                                 :label="'授权项目'"></el-table-column>
                                <el-table-column prop="planeTypeName"
                                                 :label=" '机型'"></el-table-column>
                                <el-table-column prop="awardTime"
                                                 width=100px
                                                 :label="'颁发日期'"
                                                 :formatter="dateFormat"></el-table-column>
                                <el-table-column prop="effectiveTime"
                                                 width=100px
                                                 :label="'失效日期'"
                                                 :formatter="dateFormat"></el-table-column>
                                <el-table-column prop="remainingDate"
                                                 width=80px
                                                 :label="'剩余天数'"></el-table-column>
                                <el-table-column prop="statusName"
                                                 width=80px
                                                 :label="'状态'">
                                    <template slot-scope="scope">
                                        <span style="color:red;" v-if="scope.row.status==='remind'||scope.row.status==='newer'">{{scope.row.statusName}}</span>
                                        <span v-else>{{scope.row.statusName}}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </div>
                </el-card>
            </el-form>
        <div class="base-info__pic" style="width:10%">
            <img :src="formData.picUrlSSO+'?size=normal'"
                 alt>
        </div>
    </div>
    </div>
</template>

<script>
import UserSelect from '@/components/UserSelect';
export default {
    name: 'PersonalBaseInfo',
    components: {
        UserSelect
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        kind: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            loading: false,
            formData: {
                id: '',
                base: '',
                birthday: '',
                birthplace: '',
                center: '',
                contactAddress: '',
                countryId: '',
                domesticRegion: '',
                enterCompanyOn: '',
                jobNumber: '',
                kind: '', //1为飞行、2为乘务、3为安全、4为签派、5为维修、9为其它
                nation: '',
                phone: '',
                picUrl: '',
                position: '',
                postcode: '',
                remark: '',
                residentialAddress: '',
                sex: '',
                team: ''
            },
            LicenceFormData: [],
            GualificationsFormData: [],
            TechnicalGradeFormData: [],
            TypePostFormData: [],
            TypeProjectFormData: [],
            keyValues: {
                sex: [],
                nation: [],
                country: [],
                position: [],
                countryCode: [],
                team: [],
                center: [],
                base: [],
                domesticRegion: [],
                user: [],
            },
            province: [],
        };
    },
    computed: {},
    methods: {
        async bindKeyValues() {
            const data = await Promise.all([
                this.$client.getKeyValue('ESex'),
                this.$client.getKeyValue('NationType'),
                this.$client.getCountryKeyValue(),
                this.$client.getCountryIdCode(),
                this.$client.getKeyValue('Team', this.kind),
                this.$client.getKeyValue('Center', this.kind),
                this.$client.getKeyValue('DutyInChinese', this.kind),
                this.$client.getKeyValue('DomesticRegion'),
                this.$client.getCompanyBaseKeyValue(),
                this.$client.getUserNameKeyValue()
            ]);
            this.keyValues.sex = data[0] || [];
            this.keyValues.nation = data[1] || [];
            this.keyValues.country = data[2] || [];
            this.keyValues.countryCode = data[3] || [];
            this.keyValues.team = data[4] || [];
            this.keyValues.center = data[5] || [];
            this.keyValues.position = data[6] || [];
            this.keyValues.domesticRegion = data[7] || [];
            this.keyValues.base = data[8] || [];
            this.keyValues.user = data[9] || [];
            this.$client.getKeyValue('Province').then(data => {
                this.province = data;
            });
        },
        async initFormValue() {
            await this.bindKeyValues();
            if (this.id) {
                this.getStaffBaseInfo(this.id);
                this.getLicenceInfo(this.id);
                this.getQualificationsInfo(this.id);
                this.getTechnicalGradeInfo(this.id);
                this.getTypePostInfo(this.id);
                this.getTypeProjectInfo(this.id);
            }
        },
        dateFormat(row, column) {
            const date = row[column.property];
            if (date === undefined) {
                return '';
            }
            return this.$dayjs(date).format('YYYY-MM-DD');
        },
        selectSearchHandle(key) {
            if (key === '') {
                return;
            }
            this.loading = true;
            this.$client.searchUserByKey(key).then(data => {
                this.keyValues.user = data || [];
                this.loading = false;
            });
        },
        getProvince() {
            if (!this.formData.countryId) {
                this.province = [];
                return;
            }
            const country = this.keyValues.countryCode.find(item => item.key === this.formData.countryId);
            if (country) {
                this.$client.getKeyValue('Province', country.code).then(data => {
                    this.province = data;
                });
            }
        },
        getUserJobNumber() {
            const id = this.formData.id;
            if (id) {
                this.$client.getStaffJubNumber(id).then(data => {
                    this.formData.jobNumber = data;
                });
            }
        },
        getStaffBaseInfo(id) {
            this.$client.findStaff(id).then(data => {
                this.formData = data || {};
                this.getUserJobNumber();
                this.getProvince();
            });
        },
        getLicenceInfo(id) {
            this.$client
                .getLicenceInfosWithoutPrivilege({staffId: id, type: 'License'})
                .then(data => {
                    this.LicenceFormData = data.items || {};
                });
        },
        getQualificationsInfo(id) {
            this.$client
                .getQualificationsInfosWithoutPrivilege({staffId: id, type: 'Qualification'})
                .then(data => {
                    this.GualificationsFormData = data.items || {};
                });
        },
        getTechnicalGradeInfo(id) {
            this.$client
                .getTechnicalGradeInfosWithoutPrivilege({staffId: id, type: 'Technic'})
                .then(data => {
                    this.TechnicalGradeFormData = data.items || {};
                });
        },
        getTypePostInfo(id) {
            this.$client
                .getPersonPostAuthorizations({staffId: id})
                .then(data => {
                    this.TypePostFormData = data.items || {};
                });
        },
        getTypeProjectInfo(id) {
            this.$client
                .getPersonProjectAuthorizations({staffId: id})
                .then(data => {
                    this.TypeProjectFormData = data.items || {};
                });
        },
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
.base-info__pic {
    width: 200px;
    height: auto;
    margin-left: 20px;
    & > img {
        width: 100%;
    }
}
/deep/.el-input.is-disabled .el-input__inner {
    color: black;
}
    /deep/.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 8px;
        width: 80%;
        margin-left: 40px;
    }
</style>

