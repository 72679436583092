<template>
  <div class="ui-layout-content--narrow ui-layout-flex ui-layout-flex--column" id="Accident">
    <div v-show="list.length < 1" class="ui-align--center">
      <span class="el-table__empty-text">{{'暂无数据'}}</span>
    </div>
    <div v-for="(item, index) in list" :key="index" class="ui-align--right unsafe-event__container" style="padding-bottom: 10px;">
        <el-card class="box-card" :style="{margin: '0 0 10px 0'}">
            <div class="text item">
                <el-form size="mini" ref="form" :model="item">
                    <table cellspacing="0"
                           cellpadding="0"
                           border="0"
                           class="el-table el-table--border unsafe-table">
                        <tbody>
                            <tr>
                                <td>{{'发生日期'}}</td>
                                <td>
                                    <el-form-item prop="happenDate" style="width: 100%">
                                        <el-date-picker v-model="item.happenDate"
                                                        :disabled="true"
                                                        format="yyyy / MM / dd /"
                                                        style="width: 100%"></el-date-picker>
                                    </el-form-item>
                                </td>
                                <td>{{'事件种类'}}</td>
                                <td>
                                    <el-form-item prop="accidentType">
                                        <el-select filterable v-model="item.accidentType" :disabled="true">
                                            <el-option v-for="item in accidentType"
                                                       :key="item.key"
                                                       :value="item.key"
                                                       :label="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </td>
                            </tr>
                            <tr>
                                <td>{{'发生地点'}}</td>
                                <td>
                                    <el-form-item prop="happenPlace">
                                        <el-input v-model="item.happenPlace" :disabled="true"></el-input>
                                    </el-form-item>
                                </td>
                                <td>{{'飞机注册/航班号'}}</td>
                                <td>
                                    <el-form-item prop="regOrFlightNo">
                                        <el-input v-model="item.regOrFlightNo" :disabled="true"></el-input>
                                    </el-form-item>
                                </td>
                            </tr>
                            <tr>
                                <td>{{'职务'}}</td>
                                <td>
                                    <el-form-item prop="position">
                                        <el-input v-model="item.position" :disabled="true"></el-input>
                                    </el-form-item>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{{'简况和结论'}}</td>
                                <td colspan="3">
                                    <el-form-item prop="description">
                                        <el-input type="textarea" v-model="item.description" :disabled="true"></el-input>
                                    </el-form-item>
                                </td>
                            </tr>

                            <tr>
                                <td>{{'公文号'}}</td>
                                <td>
                                    <el-form-item prop="documentNo">
                                        <el-input v-model="item.documentNo" :disabled="true"></el-input>
                                    </el-form-item>
                                </td>
                                <td>{{'附件'}}</td>
                                <td>
                                    <el-button size="small"
                                               type="primary"
                                               @click="showUploadFileDialog(index)">{{'查看附件'}}</el-button>
                                </td>
                            </tr>
                            <tr>
                                <td>{{'记录人'}}</td>
                                <td>
                                    <el-form-item>
                                        <el-input v-model="item.updaterName" :disabled="true"></el-input>
                                    </el-form-item>
                                </td>
                                <td>{{'记录时间'}}</td>
                                <td>
                                    <el-form-item>
                                        <el-input v-model="item.updateTime" :disabled="true"></el-input>
                                    </el-form-item>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </el-form>
            </div>
        </el-card>
    </div>
    <el-dialog
      width="1000px"
      :title="dialog.title"
      v-if="dialog.visible"
      :visible.sync="dialog.visible"
      :modal-append-to-body="true"
      :close-on-click-modal="false"
      :append-to-body="true"
      :show-close="true"
    >
      <upload-file :dataId="dataId" :type="this.dialog.type"></upload-file>
    </el-dialog>
  </div>
</template>
<script>
// import constants from '@/utils/constants';
export default {
    name: 'PersonalAccidentInfo',
    props: {
        id: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        kind: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            // constants,
            list: [],
            accidentType: [],
            editIndex: -1,
            dataId: '', //数据id
            dialog: {
                visible: false,
                title: '附件',
                currentId: '',
                type: 'detail'
            }
        };
    },
    methods: {
        showUploadFileDialog(index) {
            this.editIndex = index;
            const data = this.list[this.editIndex];
            this.dataId = data ? data.id : '';
            if (!this.dataId) {
                this.$message({
                    message: '请先增加信息，再维护附件',
                    type: 'warning'
                });
                return;
            }
            this.dialog.visible = true;
        },
        getStaffAccidents(staffId) {
            this.$client.getStaffAccidentList(staffId).then(data => {
                this.list = data || [];
            });
        }
    },
    created() {
        // todo:配置到字典里，但数据库里不存字典ID而是值
        this.accidentType = [
            {key: '一般事故症候', value: '一般事故症候'},
            {key: '严重事故', value: '严重事故'},
            {key: '一般事故', value: '一般事故'},
            {key: '严重事故症候', value: '严重事故症候'}
        ];

        this.getStaffAccidents(this.id);
    }
};
</script>
<style lang="less" scoped>
.el-form-item--mini.el-form-item {
    margin-bottom: 0;
}

.icon {
    cursor: pointer;
    &:hover
    {
        color: #486DD5;
    }
}

.unsafe-event__op,
.unsafe-op__add {
    & > i {
        font-size: 18px;
        margin: 0 5px;
        .icon();
    }
}

.unsafe-op__add {
    & > i {
        font-size: 22px;
    }
}

.unsafe-table {
    margin-bottom: 15px;
    td {
        text-align: center !important;
        padding: 6px 15px;
        min-height: 41px;
    }
}
/deep/.el-input.is-disabled .el-input__inner {
    color: black;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
    background-color: #f5f7fa;
    border-color: #e4e7ed;
    cursor: not-allowed;
    color: black;
}
</style>


