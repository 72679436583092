//默认机组动态
import _ from 'lodash';
import dayjs from 'dayjs';
const aircrewDynamic = {
    background: '#baefbc',
    color: 'black'
};
//默认航班动态样式
const defaultFlight = {
    wrapStyle: {
        //边框
    },
    //上半
    startApStyle: {
        //文字
        color: '#ffffff',
        //背景
        background: 'RGBA(72, 109, 213, 1)'
    },
    //下半
    endApStyle: {
        //文字
        color: '#ffffff',
        //背景
        background: 'RGBA(72, 109, 213, 1)'
    }
};
const generateStyle = (dynamics, group) => {
    //航班任务或随机动态
    if (dynamics.missionDetailType === 'flightdynamic' || (dynamics.missionDetailType === 'aircrewdynamic' && dynamics.personType === 'FOLLOW')) {
        const style = _.cloneDeep(defaultFlight);
        //航班取消
        if (dynamics.flightExecutionStatus === 'FlightExecutionStatusCanceled') {
            style.startApStyle.background = 'RGBA(255,57,57, 0.8)';
            style.endApStyle.background = 'RGBA(255,57,57, 0.8)';
        }
        //脱岗
        if (dynamics.isOffWork) {
            style.startApStyle.background = 'RGBA(190,190,190, 0.8)';
            style.endApStyle.background = 'RGBA(190,190,190, 0.8)';
        }
        //检查
        if (dynamics.isInspector) {
            style.startApStyle.background = 'RGBA(217,133,60, 0.8)';
        }
        //被检查
        if (dynamics.isBeingInspector) {
            style.endApStyle.background = 'RGBA(217,133,60, 0.8)';
        }
        //带飞
        if (dynamics.isInstructiona) {
            style.startApStyle.background = 'RGBA(122,36,38, 0.8)';
        }
        //被带飞
        if (dynamics.isBeingInstructiona) {
            style.endApStyle.background = 'RGBA(122,36,38, 0.8)';
        }
        //随机动态
        if (dynamics.personType === 'FOLLOW') {
            style.startApStyle.background = 'RGBA(238,143,255, 0.8)';
            style.endApStyle.background = 'RGBA(238,143,255, 0.8)';
            //随机占座非执行航班
            if (dynamics.isOccupancyNotRunFlight) {
                style.endApStyle.background = 'RGBA(101,172,54, 0.8)';
            }
            //随机占座执行航班
            if (dynamics.isOccupancyRunFlight) {
                style.endApStyle.background = 'RGBA(19,82,255, 0.8)';
            }
        }
        //当段备降返航
        if (dynamics.isBackOrAlternate && dynamics.actualArriveAp) {
            style.endApStyle.color = '#ff3939';
        }
        //上段备降返航
        if (dynamics.isBackOrAlternate && dynamics.actualArriveAp === null) {
            style.startApStyle.color = '#ff3939';
        }
        //是否发布
        if (!group.isRelease) {
            style.wrapStyle.border = 'solid 1px #ff3939';
        }
        return style;
    }
    const style = _.cloneDeep(aircrewDynamic);
    //计入值勤
    if (dynamics.isOnDuty) {
        style.background = '#a1ceff';
    }
    if (!group.isRelease) {
        style.border = 'solid 1px #ff3939';
    }
    // eslint-disable-next-line
    if (dynamics.missionDetailType === 'stoprecoverfly') {
        style.background = 'RGBA(255,57,57, 0.8)';
        style.color = 'white';
        style.border = 'none';
    }
    // eslint-disable-next-line
    if (dynamics.missionDetailType === 'seatschedule') {
        style.background = 'RGBA(72, 109, 213, 1)';
        style.color = 'white';
    }

    return style;
};
export const transformStaffList = staffList => {
    if (!Array.isArray(staffList)) {
        throw new Error('staffList must be a Array');
    }
    return staffList.map(item => {
        const flags = {
            medicalCertificate: [],
            specialAirportRun: [],
            SpecialAirportRunCopilot: [],
            internationalRouteQualification: [],
            RNPAPCH: []
        };
        const weekFlyDutyTime = item.weekFlyDutyTimeStr.split(':');
        const monthFlyDutyTime = item.monthFlyDutyTimeStr.split(':');
        const monthFlyTime = item.monthFlyTimeStr.split(':');
        const yearFlyTime = item.yearFlyTimeStr.split(':');
        item._weekFlyDutyTimeStr = parseInt(weekFlyDutyTime[0] * 60) + parseInt(weekFlyDutyTime[1]);
        item._monthFlyDutyTimeStr = parseInt(monthFlyDutyTime[0] * 60) + parseInt(monthFlyDutyTime[1]);
        item._monthFlyTimeStr = parseInt(monthFlyTime[0] * 60) + parseInt(monthFlyTime[1]);
        item._yearFlyTimeStr = parseInt(yearFlyTime[0] * 60) + parseInt(yearFlyTime[1]);
        if (Array.isArray(item.userCredentialInfos)) {
            item.userCredentialInfos.forEach(userCredent => {
                if (userCredent.credentialCode) {
                    //UserCredentialInfos.CredentialCode 包含 'MedicalCertificate'
                    if (userCredent.credentialCode.includes('MedicalCertificate')) {
                        flags.medicalCertificate.push(userCredent);
                    }
                    if (userCredent.credentialCode === 'SpecialAirportRun') {
                        flags.specialAirportRun.push(userCredent);
                    }
                    if (userCredent.credentialCode === 'SpecialAirportRunCopilot') {
                        flags.SpecialAirportRunCopilot.push(userCredent);
                    }
                    if (userCredent.credentialCode.includes('InternationalRouteQualification')) {
                        flags.internationalRouteQualification.push(userCredent);
                    }
                    if (userCredent.credentialCode === 'RNPAPCH') {
                        flags.RNPAPCH.push(userCredent);
                    }
                }
            });
        }

        return {
            ...item,
            flags
        };
    });
};
//gantt图mission处理函数
export const transformMissions = staffMissions => {
    const ret = {};
    staffMissions.forEach(staffMission => {
        const key = staffMission.staffId;
        ret[key] = {
            //执行期
            executionPhase: [],
            //组环
            groupLoop: [],
            //航班
            loopFlight: [],
            //机组动态
            dynamics: [],
            //延误条
            delayBar: [],
            //车程
            driveBar: []
        };
        for (let i = 0, len = staffMission.missions.length; i < len; i++) {
            const mission = staffMission.missions[i];
            ret[key].driveBar.push({
                startTime: mission.startTimeOnDuty,
                endTime: mission.startTimeOnFlyDuty
            });
            ret[key].driveBar.push({
                startTime: mission.endTimeOnFlyDuty,
                endTime: mission.endTimeOnDuty
            });
            mission.isOnFlyDuty === true &&
                ret[key].executionPhase.push({
                    ...mission,
                    missionDetailGroups: void 0
                });
            for (let i = 0, len = mission.missionDetailGroups.length; i < len; i++) {
                const group = mission.missionDetailGroups[i];
                if (!group.missionDetails || group.missionDetails.length < 1) {
                    break;
                }
                //获取组环航班动态最早的开始时间
                const startTime = _.minBy(group.missionDetails, 'startTime').startTime;
                //获取组环航班动态最晚的结束时间
                const endTime = _.maxBy(group.missionDetails, 'endTime').endTime;
                // const plannedStartTime = _.minBy(group.missionDetails, 'plannedStartTime').plannedStartTime;
                // const plannedEndTime = _.maxBy(group.missionDetails, 'plannedEndTime').plannedEndTime;
                ret[key].groupLoop.push({
                    ...group,
                    missionDetails: void 0,
                    startTime,
                    staffId: key,
                    endTime,
                    linkStartTime: mission.startTimeOnDuty,
                    linkEndTime: mission.endTimeOnDuty
                });
                // const diffTimeStamp = dayjs(startTime).diff(dayjs(plannedStartTime), 'second');
                // diffTimeStamp > 0 &&
                //     ret[key].delayBar.push({
                //         startTime: plannedStartTime,
                //         endTime: plannedEndTime,
                //         actualStartTime: startTime,
                //         diffTimeStamp
                //     });
                for (let i = 0, len = group.missionDetails.length; i < len; i++) {
                    const dynamics = group.missionDetails[i];
                    //延误条
                    const diffTimeStamp = dayjs(dynamics.startTime).diff(dayjs(dynamics.plannedStartTime), 'second');
                    diffTimeStamp > 0 &&
                        ret[key].delayBar.push({
                            startTime: dynamics.plannedStartTime,
                            endTime: dynamics.plannedEndTime,
                            actualStartTime: dynamics.startTime,
                            diffTimeStamp
                        });
                    if (
                        dynamics.missionDetailType === 'flightdynamic' ||
                        (dynamics.missionDetailType === 'aircrewdynamic' && dynamics.personType === 'FOLLOW')
                    ) {
                        ret[key].loopFlight.push({
                            ...dynamics,
                            staffId: key,
                            style: generateStyle(dynamics, group)
                        });
                    } else {
                        ret[key].dynamics.push({
                            ...dynamics,
                            staffId: key,
                            style: generateStyle(dynamics, group)
                        });
                    }
                }
            }
        }
    });
    return ret;
};
//日历组件mission处理函数
export const transformMissionsForCalendar = staffMissions => {
    const ret = {};
    const pushHandle = obj => {
        if (ret[obj._type] == null) {
            ret[obj._type] = [obj];
        } else {
            ret[obj._type].push(obj);
        }
        // const start = dayjs(obj.startTime).format('YYYY-MM-DD');
        // const end = dayjs(obj.endTime).format('YYYY-MM-DD');
        // ret[start] || (ret[start] = {});
        // ret[end] || (ret[end] = {});

        // const diff = dayjs(end).diff(dayjs(start), 'day', true) - 1;

        //该mission的开始和结束都在同一天，如果不在同一天，拆分一下
        // if (diff <= 0) {
        //     if (ret[start][obj._type] == null) {
        //         ret[start][obj._type] = [obj];
        //     } else {
        //         ret[start][obj._type].push(obj);
        //     }
        // } else {

        // if (ret[start][obj._type] == null) {
        //     ret[start][obj._type] = [obj];
        // } else {
        //     ret[start][obj._type].push(obj);
        // }
        // if (ret[end][obj._type] == null) {
        //     ret[end][obj._type] = [obj];
        // } else {
        //     ret[end][obj._type].push(obj);
        // }
        //Bug #42937
    };
    staffMissions.forEach(staffMission => {
        for (let i = 0, len = staffMission.missions.length; i < len; i++) {
            const mission = staffMission.missions[i];
            pushHandle({
                _startTime: mission.startTimeOnDuty,
                _endTime: mission.startTimeOnFlyDuty,
                _type: 'driveBar'
            });
            pushHandle({
                _startTime: mission.endTimeOnFlyDuty,
                _endTime: mission.endTimeOnDuty,
                _type: 'driveBar'
            });
            mission.isOnFlyDuty === true &&
                pushHandle({
                    ...mission,
                    missionDetailGroups: void 0,
                    _type: 'executionPhase',
                    _startTime: mission.startTimeOnDuty,
                    _endTime: mission.endTimeOnDuty
                });
            for (let i = 0, len = mission.missionDetailGroups.length; i < len; i++) {
                const group = mission.missionDetailGroups[i];
                if (!group.missionDetails || group.missionDetails.length < 1) {
                    break;
                }
                //获取组环航班动态最早的开始时间
                const startTime = _.minBy(group.missionDetails, 'startTime').startTime;
                //获取组环航班动态最晚的结束时间
                const endTime = _.maxBy(group.missionDetails, 'endTime').endTime;
                // const plannedStartTime = _.minBy(group.missionDetails, 'plannedStartTime').plannedStartTime;
                // const plannedEndTime = _.maxBy(group.missionDetails, 'plannedEndTime').plannedEndTime;
                pushHandle({
                    ...group,
                    missionDetails: void 0,
                    _startTime: startTime,
                    _endTime: endTime,
                    _type: 'groupLoop'
                });
                // const diffTimeStamp = dayjs(startTime).diff(dayjs(plannedStartTime), 'second');
                // diffTimeStamp > 0 &&
                //     pushHandle({
                //         _startTime: dynamics.plannedStartTime,
                //         _endTime: dynamics.plannedEndTime,
                //         actualStartTime: dynamics.startTime,
                //         diffTimeStamp,
                //         _type: 'delayBar'
                //     });
                for (let i = 0, len = group.missionDetails.length; i < len; i++) {
                    const dynamics = group.missionDetails[i];
                    const diffTimeStamp = dayjs(dynamics.startTime).diff(dayjs(dynamics.plannedStartTime), 'second');
                    diffTimeStamp > 0 &&
                        pushHandle({
                            _startTime: dynamics.plannedStartTime,
                            _endTime: dynamics.plannedEndTime,
                            actualStartTime: dynamics.startTime,
                            diffTimeStamp,
                            _type: 'delayBar'
                        });
                    if (
                        dynamics.missionDetailType === 'flightdynamic' ||
                        (dynamics.missionDetailType === 'aircrewdynamic' && dynamics.personType === 'FOLLOW')
                    ) {
                        pushHandle({
                            ...dynamics,
                            _startTime: dynamics.startTime,
                            _endTime: dynamics.endTime,
                            style: generateStyle(dynamics, group),
                            _type: 'loopFlight'
                        });
                    } else {
                        pushHandle({
                            ...dynamics,
                            _startTime: dynamics.startTime,
                            _endTime: dynamics.endTime,
                            style: generateStyle(dynamics, group),
                            _type: 'dynamics'
                        });
                    }
                }
            }
        }
    });
    return ret;
};

//人员信息提示模板
export const staffTipRender = function(obj) {
    const mapFn = credential => {
        return `<div class='tooltip-item'>
        ${credential.credentialName}
        ${credential.airportStr || ''}
        ${credential.route || ''}
        ${credential.level || ''}
            ${dayjs(credential.getTime).format('YYYY-MM-DD')}至
            ${dayjs(credential.expireTime).format('YYYY-MM-DD')}
        </div>`;
    };
    //证照
    const licenses = obj.userCredentialInfos
        .filter(item => item.credentialType === 'license')
        .map(mapFn)
        .join('');
    //技术等级
    const technic = obj.userCredentialInfos
        // eslint-disable-next-line
        .filter(item => item.credentialType === 'technic')
        .map(mapFn)
        .join('');
    //资质
    const qualification = obj.userCredentialInfos
        .filter(item => item.credentialType === 'qualification')
        .map((credential, index) => {
            if (index % 2 === 0) {
                return `<tr><td style="padding: 2px 5px; width: 50%">
                ${credential.credentialName}
                ${credential.airportStr || ''}
                ${credential.route || ''}
                ${credential.level || ''}
                </td>`;
            }
            return `<td style="padding: 2px 5px;"  width: 50%">
                ${credential.credentialName}
                ${credential.airportStr || ''}
                ${credential.route || ''}
                ${credential.level || ''}
                </td></tr>`;
        }).join('');
    let a = '';
    if (obj.lockName !== null) {
        a = `<div>${'锁定人：'}${obj.lockName}</div>`;
    }
    const tip = `<div class='tooltip-content'>
                    <div class='tooltip-title'>${'人员资质'}</div>
                       <table>
                            <tbody>
                                ${qualification}
                            </tbody>
                       </table>
                    <div class='tooltip-title'>${'人员证照'}</div>
                        ${licenses}
                    <div class='tooltip-title'>${'技术等级'}</div>
                        ${technic}
                    <div>${'常住地：'}${obj.baseAirport}</div>
                    ${a}
               </div>`;
    return tip;
};

export const executionPhaseTipRender = function(obj) {
    const tip = `
            <div class='tooltip-content'>
            <div class='tooltip-title'>
            ${'实际值勤信息'}
            </div>
            <div class='tooltip-item'>
            ${'休息期结束时间：'}
            ${this.$dayjs(obj.startTimeOnDuty).format('YYYY-MM-DD HH:mm')}
            </div>
            <div class='tooltip-item'>
            ${'休息期开始时间：'}
            ${this.$dayjs(obj.endTimeOnDuty).format('YYYY-MM-DD HH:mm')}
            </div>
            <div class='tooltip-item'>
            ${'非休息期时间：'}
            ${obj.dutyTimeStr}
            </div>
            <div class='tooltip-item'>
            ${'飞行值勤开始时间：'}
            ${this.$dayjs(obj.startTimeOnFlyDuty).format('YYYY-MM-DD HH:mm')}
            </div>
            <div class='tooltip-item'>
            ${'飞行值勤结束时间：'}
            ${this.$dayjs(obj.endTimeOnFlyDuty).format('YYYY-MM-DD HH:mm')}
            </div>
            <div class='tooltip-item'>
            ${'飞行值勤时间：'}
            ${obj.flyDutyTimeStr}
            </div>
            <div class='tooltip-item'>
            ${'飞行时间：'}
            ${obj.flyTimeStr}
            </div>
            </div>`;
    return tip;
};
export const groupTipRender = function(obj) {
    return `
        <div>
        ${'备注：'}${obj.remark || ''}
        </div>`;
};
export const flightTipRender = function(obj) {
    let postName = '';
    if (obj.personType === 'FOLLOW') {
        postName = `${obj.dataName} ${obj.dataNameParent || ''}`;
    } else {
        postName = obj.crewPosts.map(item => item.postName).join('，');
    }
    const arriveAp =
        obj.actualArriveApName == null ?
            obj.plannedArriveApName :
            `${obj.actualArriveApName}(${obj.plannedArriveApName})`;
    const tip = `
        <div class='tooltip-item'>
        ${'航班号：'}
        ${obj.flightNo}</div>
        <div class='tooltip-item'>
        ${'起飞机场：'}
        ${obj.plannedDepartureApName}(${obj.plannedDepartureApIataCode})</div>
        <div class='tooltip-item'>
        ${'目的机场：'}
        ${arriveAp}(${obj.plannedArriveApIataCode})
        </div>
        <div class='tooltip-item'>
        ${'飞机号：'}
        ${obj.registrationNum}</div>
        <div> ${obj.planeTypeInternationalCode}</div>
        <div class='tooltip-item'>
        ${'计划起飞时间：'}
        ${this.$dayjs(obj.plannedStartTime).format('HH:mm')}
        </div>
        <div class='tooltip-item'>
        ${'计划落地时间：'}
        ${this.$dayjs(obj.plannedEndTime).format('HH:mm')}</div>
        <div class='tooltip-item'>
        ${'预计/实际起飞时间：'}
        ${this.$dayjs(obj.startTime).format('HH:mm')}</div>
        <div class='tooltip-item'>
        ${'预计/实际落地时间：'}
        ${this.$dayjs(obj.endTime).format('HH:mm')}</div>
        <div class='tooltip-item'>
         ${'飞行时间：'}
        ${obj.flyTimeStr}
        </div>
        <div class='tooltip-item'>
        ${'机上岗位：'}
        ${postName || ''}</div>
        <div class='tooltip-item'>
        ${'运行备注：'}
        ${obj.flightRemark || ''}</div>`;
    return tip;
};

export const specialBarTipRender = function(obj) {
    let tip = '';
    let name = '';
    let location = '';
    if (obj.place !== '' && obj.place !== null) {
        location = `<div class='tooltip-item'>${'动态所在地：'}${obj.place}</div>`;
    }
    if (obj.dataNameParent && obj.dataNameParent !== '') {
        name = `${obj.dataName}(${obj.dataNameParent})`;
    } else {
        name = `${obj.dataName}`;
    }
    tip = `
                <div class='tooltip-item'>
                ${'类型：'}
                ${name}
                </div>
                 <div class='tooltip-item'>
                ${'开始时间：'}
                ${this.$dayjs(obj.startTime).format('YYYY-MM-DD HH:mm')}
                </div>
                <div class='tooltip-item'>
                ${'结束时间：'}
                ${this.$dayjs(obj.endTime).format('YYYY-MM-DD HH:mm')}
                </div>
                    <div class='tooltip-item'>
                ${'备注：'}
                ${obj.aircrewDynamicRemark || ''}
                </div>
                ${location}`;
    if (obj.trainingName != null) {
        tip += `<div class='tooltip-item'>${'训练类型：'}${obj.trainingName}</div>`;
    }
    return tip;
};

export const warnBarTipRender = obj => {
    return obj.message;
};
