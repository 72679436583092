<template>
    <div>
        <el-form :model="formData" ref="form" size="mini" :disabled="type === 'detail'">
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'人员类型'" prop="staffTypeName">
                        <el-input v-model="formData.staffTypeName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'训练类别'"
                                  prop="trainName">
                        <el-input v-model="formData.trainName"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'训练方式'"
                                  prop="trainTypeName">
                        <el-input v-model="formData.trainTypeName"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'训练地点'" prop="trainPlace">
                        <el-input v-model="formData.trainPlace"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'说明'" prop="trainingTitle">
                        <el-input v-model="formData.trainingTitle"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'开始日期'" prop="trainingStartDate">
                        <el-input v-model="formData.trainingStartDate" :formatter="dateFormat"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'结束日期'" prop="trainingEndDate">
                        <el-input v-model="formData.trainingEndDate" :formatter="dateFormat"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'训练机构'" prop="implOrg">
                        <el-input v-model="formData.implOrg"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'PersonalTrainingInfoForm',
    props: {
        trainRecordId: {
            type: String,
            default: ''
        },
        account: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        closeModal: {
            type: Function
        }
    },
    data() {
        return {
            formData: {
                staffTypeName: '',
                trainName: '',
                trainTypeName: '',
                trainPlace: '',
                trainingTitle: '',
                trainingStartDate: '',
                trainingEndDate: '',
                implOrg: ''
            }
        };
    },
    methods: {
        dateFormat(row, column) {
            const date = row[column.property];
            if (date === undefined) {
                return '';
            }
            return this.$dayjs(date).format('YYYY-MM-DD');
        },
        async initFormValue() {
            this.getUserTrainRecordDetail(this.trainRecordId, this.account);
        },
        getUserTrainRecordDetail(trainRecordId, account) {
            this.$client
                .getUserTrainRecordDetail({
                    params: {
                        trainRecordId,
                        account
                    }
                })
                .then(data => {
                    this.formData = data || {};
                });
        }
    },
    created() {
        this.initFormValue();
    }
};
</script>
<style lang="less" scoped>
    /deep/.el-input.is-disabled .el-input__inner {
        color: black;
    }
</style>
