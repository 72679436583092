<template>
    <div class="ui-layout-container ui-height-100">
        <div class="ui-layout-content--full">
            <el-date-picker v-model="date" type="month" placeholder="选择日期" @change="changeHandle"></el-date-picker>
            <el-button-group>
                <el-button size="mini" @click="switchPrevMonth">{{'上个月'}}</el-button>
                <el-button size="mini" @click="switchNextMonth">{{'下个月'}}</el-button>
            </el-button-group>
        </div>
        <div class="ui-layout-content--wide" v-loading="loading">
            <ex-calendar ref="calendar"></ex-calendar>
        </div>
    </div>
</template>
<script>
import {
    transformMissionsForCalendar,
    staffTipRender,
    executionPhaseTipRender,
    groupTipRender,
    flightTipRender,
    specialBarTipRender,
    warnBarTipRender
} from '@/utils/gantt-helper';
import ExCalendar from '../../components/ExCalendar/index';
import {mapGetters} from 'vuex';

export default {
    name: 'GanttTest',
    data() {
        return {
            loading: false,
            date: this.$dayjs().format('YYYY-MM')
        };
    },
    components: {
        ExCalendar
    },
    computed: {
        ...mapGetters(['userInfo']),
        staffInfoId() {
            return this.userInfo.id;
        }
    },
    methods: {
        switchPrevMonth() {
            this.date = this.$dayjs(this.date).subtract(1, 'month');
            this.changeHandle();
        },
        switchNextMonth() {
            this.date = this.$dayjs(this.date).add(1, 'month');
            this.changeHandle();
        },
        changeHandle() {
            this.init();
        },
        async init() {
            this.loading = true;
            const data = await this.$client.getStaffMissions({
                staffIds: [this.staffInfoId],
                startTime: this.$dayjs(this.date)
                    .startOf('month')
                    .format('YYYY-MM-DD'),
                endTime: this.$dayjs(this.date)
                    .endOf('month')
                    .format('YYYY-MM-DD'),
                isRelease: true,
                isShowInSchedule: false
            });
            const missions = transformMissionsForCalendar(data);

            this.$refs.calendar.initialization({
                date: this.date,
                render: {
                    //人员信息提示渲染函数
                    staffTipRender: staffTipRender.bind(this),
                    //执行期提示渲染函数
                    executionPhaseTipRender: executionPhaseTipRender.bind(this),
                    //组环提示渲染函数
                    groupTipRender: groupTipRender.bind(this),
                    //航班信息提示渲染函数
                    flightTipRender: flightTipRender.bind(this),
                    //机组动态提示渲染函数
                    specialBarTipRender: specialBarTipRender.bind(this),
                    //预警区域提示
                    warnBarTipRender
                },
                tipOpenDelay: 1000,
                //一天分为几格显示
                timeScale: 4,
                //每小时占用的宽度
                hourSpace: 9
            });
            this.$refs.calendar.setMission(missions);
            this.loading = false;
        }
    },
    mounted() {
        this.init();
    }
};
</script>
<style lang="less" scoped>
/deep/.tooltip-content {
    .tooltip-title {
        font-weight: 600;
        margin: 10px 0 0 0;
        &:first-of-type {
            margin: 0;
        }
    }
}
/deep/ .tooltip-item {
    padding: 5px 0;
}
</style>

