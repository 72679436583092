<template>
    <div>
        <el-tabs style="height:100%" v-model="currentTabName" tab-position="left">
            <el-tab-pane
                :label="'基本信息'"
                name="personal-base-info"
            >
                <personal-base-info :id="staffInfoId" :kind="this.kind"></personal-base-info>
            </el-tab-pane>
            <el-tab-pane
                :label="'经历信息'"
                name="personal-experience-info"
            >
                <personal-experience-info :id="staffInfoId" :kind="this.kind"></personal-experience-info>
            </el-tab-pane>
            <el-tab-pane
                :label="'训练情况'"
                name="personal-training-info"
            >
                <personal-training-info :id="staffInfoId" :kind="this.kind"></personal-training-info>
            </el-tab-pane>
            <el-tab-pane
                :label="'不安全事件'"
                name="personal-accident-info"
            >
                <personal-accident-info :id="staffInfoId" :kind="this.kind"></personal-accident-info>
            </el-tab-pane>
            <el-tab-pane
                :label="'奖惩记录'"
                name="personal-reward-and-punishment-info"
            >
                <personal-reward-and-punishment-info :id="staffInfoId" :kind="this.kind"></personal-reward-and-punishment-info>
            </el-tab-pane>
            <el-tab-pane
                :label="'排班情况'"
                name="personal-schedule-info"
            >
                <personal-schedule-info :id="staffInfoId" :kind="this.kind"></personal-schedule-info>
            </el-tab-pane>
            <el-tab-pane
                :label="'消息中心'"
                name="message-center"
            >
                <message-center :id="staffInfoId" :kind="this.kind"></message-center>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import PersonalBaseInfo from './PersonalBaseInfo';
import PersonalExperienceInfo from './PersonalExperienceInfo';
import PersonalRewardAndPunishmentInfo from './PersonalRewardAndPunishmentInfo';
import PersonalTrainingInfo from './PersonalTrainingInfo';
import PersonalScheduleInfo from './PersonalScheduleInfo';
import PersonalAccidentInfo from './PersonalAccidentInfo';
import MessageCenter from './MessageCenter';
import {mapGetters} from 'vuex';
export default {
    name: 'PersonalCenterInfo',
    components: {
        PersonalBaseInfo,
        PersonalRewardAndPunishmentInfo,
        PersonalTrainingInfo,
        PersonalExperienceInfo,
        PersonalScheduleInfo,
        PersonalAccidentInfo,
        MessageCenter
    },
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            currentTabName: 'personal-base-info',
            staffId: '',
            kind: '',
        };
    },
    computed: {
        ...mapGetters(['userInfo']),
        staffInfoId() {
            return this.userInfo.id;
        }
    },
    methods: {},
    created() {
        this.$client.findStaff(this.userInfo.id).then(data => {
            this.findStaffList = data;
            if (data === null) {
                this.kind = 'fly';
            } else {
                this.kind = data.kind;
            }
        });
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs__content {
    height: 750px;
    overflow-y: auto;
}
/deep/ .el-tabs--left .el-tabs__item.is-left {
    text-align: right;
    height: 80px;
    line-height: 80px;
}
/deep/.el-tabs__content {
    height: 100%;
    overflow: auto;
}
</style>


