<template>
    <div :style="position" class="tool-tip__wrap" ref="ganttTip">
        <div v-html="html" class="tool-tip__body"></div>
    </div>
</template>
<script>
export default {
    name: 'GanttToolTip',
    data() {
        return {
            show: false,
            html: '',
            top: 0,
            left: 0
        };
    },
    inject: ['root'],
    computed: {
        position() {
            return {
                visibility: this.show ? 'visible' : 'hidden',
                left: this.left + 'px',
                top: this.top + 'px'
            };
        }
    },
    methods: {
        // 定位控制
        checkPosition(position) {
            const {left, top} = position;
            const selfWidth = this.$refs.ganttTip.clientWidth;
            const selfHeight = this.$refs.ganttTip.clientHeight;
            // eslint-disable-next-line
            let _left = left + 20,
                _top = top - 20;
            //下边界溢出
            if (top + selfHeight > window.innerHeight) {
                // eslint-disable-next-line
                _top = top - selfHeight < 0 ? 0 : top - selfHeight;
            }
            //左边界溢出
            if (left + selfWidth > window.innerWidth) {
                _left = left - selfWidth < 0 ? 0 : left - selfWidth;
            }
            this.left = _left;
            this.top = _top;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        toggleShow(obj) {
            const {currentData, type, show, position} = obj;
            if (show) {
                this.timer && clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    const havaRender =
                        Object.prototype.toString.call(this.root.options.render[type]) === '[object Function]';
                    if (havaRender) {
                        this.html = this.root.options.render[type](currentData);
                        this.$nextTick(() => {
                            this.checkPosition(position);
                        });
                    }
                }, this.root.options.tipOpenDelay);
                return;
            }
            this.timer && clearTimeout(this.timer);
            this.show = false;
        }
    }
};
</script>
<style lang="less" scoped>
.tool-tip__wrap {
    position: fixed;
    background: white;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 8px;
    z-index: 9999;
}
.tool-tip__body {
    overflow: hidden;
    max-width: 100%;
    min-height: 20px;
    font-size: 12px;
}
</style>


