<template>
    <div class="gantt-bar">
        <!-- 执行期 -->
        <div
            class="execution-phase"
            @mouseenter="mouseoverHandle(item, 'executionPhaseTipRender', $event)"
            @mouseleave="mouseleaveHandle"
            v-for="(item, index) in missionsData"
            :key="`mission_${index}`"
            :style="ganeratePosition(item)"
        ></div>
        <!-- 组环 -->
        <div
            v-for="(item, index) in missionGroups"
            @mouseenter="mouseoverHandle(item, 'groupTipRender', $event)"
            @mouseleave="mouseleaveHandle"
            :key="`missionGroup_${index}`"
            class="flight-group"
            :style="ganeratePosition(item)"
        ></div>
        <!-- 航班 -->
        <div
            v-for="(item, index) in missionDetails"
            @mouseenter="mouseoverHandle(item, 'flightTipRender', $event)"
            @mouseleave="mouseleaveHandle"
            :key="`missionDetail_${index}`"
            class="flight-item"
            :style="ganeratePosition(item)"
        >
            <div
                class="flight-item__departure"
                :style="item.style && item.style.startApStyle || {}"
            >{{item.plannedDepartureApName}}</div>
            <div
                class="flight-item__arrive"
                :style="item.style && item.style.endApStyle || {}"
            >{{ item.actualArriveApName || item.plannedArriveApName}}</div>
        </div>
        <!-- 机组动态 -->
        <div
            @mouseenter="mouseoverHandle(item, 'specialBarTipRender', $event)"
            @mouseleave="mouseleaveHandle"
            v-for="(item, index) in dynamics"
            class="special-item"
            :key="`dynamic_${index}`"
            :style="ganeratePosition(item)"
        >
            <div class="special-item__inner">{{item.dataName}}</div>
        </div>

        <div
            @mouseleave="mouseleaveHandle"
            @mouseenter="mouseoverHandle(item, 'specialBarTipRender', $event)"
            v-for="(item, index) in dynamicsFllow"
            :key="`dynamicsFllow_${index}`"
            class="flight-item"
            :style="ganeratePosition(item)"
        >
            <div
                class="flight-item__departure"
                :style="item.style && item.style.startApStyle || {}"
            >{{item.plannedDepartureApName}}</div>
            <div
                class="flight-item__arrive"
                :style="item.style && item.style.endApStyle || {}"
            >{{ item.actualArriveApName || item.plannedArriveApName}}</div>
        </div>
        <!-- 机组动态 -->

        <!-- 延误条 -->
        <div
            v-for="(item, index) in delayBars"
            class="delay-bar"
            :key="`delay-bar_${index}`"
            :style="ganeratePosition(item)"
        ></div>
        <!-- 车程 -->
        <div
            v-for="(item, index) in driveBar"
            class="drive-bar"
            :key="`drive-bar_${index}`"
            :style="ganeratePosition(item)"
        ></div>
        <!-- 预警区域 -->
        <div
            @mouseenter="mouseoverHandle(item, 'warnBarTipRender', $event)"
            @mouseleave="mouseleaveHandle"
            v-for="(item, index) in warnBar"
            class="warn-bar"
            :key="`warn-bar_${index}`"
            :style="ganeratePosition(item)"
        ></div>
    </div>
</template>
<script>
const zIndexRange = {
    executionPhase: [100, 200],
    groupLoop: [300, 400],
    loopFlight: [500, 600],
    dynamics: [300, 400],
    delayBar: [200, 300],
    driveBar: [200, 300],
    warnBar: [0, 100],
    groupLink: [800, 900]
};
export default {
    name: 'calendarMission',
    props: {
        mission: {
            type: Object,
            required: true
        },
        date: {
            type: String,
            required: true
        }
    },
    inject: ['root'],
    computed: {
        //获取执行期
        missionsData() {
            return this.mission.executionPhase;
        },
        //获取组环，
        missionGroups() {
            return this.mission.groupLoop;
        },
        //航班信息
        missionDetails() {
            return this.mission.loopFlight;
        },
        //机组动态(带飞)
        dynamicsFllow() {
            return this.mission.dynamics ? this.mission.dynamics.filter(item => item.personType === 'FOLLOW') : [];
        },
        //机组动态（不是带飞）
        dynamics() {
            return this.mission.dynamics ? this.mission.dynamics.filter(item => item.personType !== 'FOLLOW') : [];
        },
        //延误条
        delayBars() {
            return this.mission.delayBar;
        },
        //车程
        driveBar() {
            return this.mission.driveBar;
        },
        //预警区域
        warnBar() {
            return this.mission.warnBar;
        }
    },
    methods: {
        transformTimeToPosition(startTime, endTime) {
            //任务条开始时间差，相对于当天
            const diffUnixOfStart = this.$dayjs(startTime).diff(this.$dayjs(this.date));
            //任务条结束时间差，相对于当天
            const diffUnix = this.$dayjs(endTime).diff(this.$dayjs(startTime));
            // eslint-disable-next-line
            const left = (diffUnixOfStart / 1000 / 60 / 60) * this.root.options.hourSpace;
            // eslint-disable-next-line
            const width = (diffUnix / 1000 / 60 / 60) * this.root.options.hourSpace;
            return {left, width};
        },

        // 根据开始时间和结束时间计算定位
        ganeratePosition(mission) {
            const {_startTime, _endTime} = mission;
            const p = this.transformTimeToPosition(_startTime, _endTime);
            let left = p.left;
            let style = mission.style || {};
            if (mission._type === 'loopFlight') {
                style = mission.style.wrapStyle;
            }

            if (mission.leftClose === false) {
                left = left - 1;
            }
            if (mission.rightClose === false) {
                left = left + 1;
            }
            return {
                ...style,
                left: left + 'px',
                width: Math.abs(p.width) + 'px',
                'z-index': this.getGanttBarZindex(p.width, mission._type)
            };
        },
        //人工智能设置各gantt条的z-index防止出现无法点击的情况
        getGanttBarZindex(width, type) {
            if (zIndexRange[type] === undefined) {
                return 0;
            }
            const [min, max] = zIndexRange[type];
            const temp = Math.round(1000 / width) + min;
            if (temp < min) {
                return min;
            }
            if (temp > max) {
                return max;
            }
            return temp;
        },
        mouseoverHandle(item, type, e) {
            this.root.tooltipHandle({
                currentData: item,
                position: {
                    top: e.clientY,
                    left: e.clientX
                },
                show: true,
                type
            });
        },
        mouseleaveHandle() {
            this.root.tooltipHandle({
                show: false
            });
        }
    }
};
</script>
<style lang="less" scoped>
.gantt-bar {
    flex: 1;
    overflow: hidden;
    .special-item,
    .execution-phase,
    .flight-group,
    .delay-bar,
    .drive-bar,
    .delay-bar__solid,
    .link-group,
    .warn-bar,
    .flight-item {
        white-space: nowrap;
        position: absolute;
        box-sizing: border-box;
        font-size: 12px;
        color: white;
        overflow: hidden;
        user-select: none;
        top: 30px;
        bottom: 0;
        &:hover {
            cursor: pointer;
            transform: scale(1.2);
            transition: all 0.1s ease-in;
        }
    }
    .warn-bar {
        z-index: 90;
        height: 100%;
        background: #ffadad;
    }
    .special-item {
        z-index: 99;
        margin: 25px 0;
        background-color: #007aff;
        border-radius: 0.5px;
        text-align: center;
        &:hover {
            z-index: 9999;
        }
        &__inner {
            display: inline-block;
            text-align: left;
        }
    }
    .execution-phase {
        z-index: 97;
        background-color: #e8e8e8;
        border-radius: 0.5px;
    }
    .flight-group {
        z-index: 99;
        border-radius: 0.5px;
        border: solid 1px #5b39ff;
        margin: 8px 0;
    }
    .flight-item {
        display: flex;
        flex-direction: column;
        z-index: 100;
        text-align: center;
        margin: 15px 0;
        &__departure,
        &__arrive {
            overflow: hidden;
            background: #007aff;
            height: 50%;
            display: inline-block;
            text-align: center;
            line-height: 1.8;
        }
        &__departure {
            border-bottom: 1px solid white;
        }
    }
    .delay-bar {
        background-color: #fbfa04;
        height: 5px;
        margin-top: 7px;
        z-index: 98;
        &__solid {
            color: red;
        }
    }
    .drive-bar {
        background-color: #9598ff;
        z-index: 98;
    }
}
</style>


