<template>
    <div class="calendar">
        <calendar-table :calendarArrWidthMission="calendarArrWidthMission"></calendar-table>
        <calendar-tooltip ref="toolTip"></calendar-tooltip>
    </div>
</template>
<script>
import {getDayCountOfMonth, getFirstDayOfMonth} from '@/utils/calendar';
import CalendarTable from './calendarTable';
import CalendarTooltip from './calendarTooltip';

const defaultOptions = {
    //一天分为几格显示
    timeScale: 4,
    //每小时占用的宽度
    hourSpace: 8
};
export default {
    name: 'ExCalendar',
    components: {
        CalendarTable,
        CalendarTooltip
    },
    data() {
        return {
            options: defaultOptions,
            update: false
        };
    },
    provide() {
        return {
            root: this
        };
    },
    computed: {
        //每天占多宽px
        dailySpace() {
            return this.options.hourSpace * 24;
        },
        //获取当前月第一天是星期几
        firstDayOfMonth() {
            return getFirstDayOfMonth(this.options.date);
        },
        //每天显示多少个刻度
        splitDay() {
            return Math.floor(24 / this.options.timeScale);
        },
        //gantt任务条
        missions() {
            if (this.update === this.update) {
                return this._missions || {};
            }
            return {};
        },
        //生成日历数组
        calendarArr() {
            const dayjs = this.$dayjs(this.options.date);
            const currentMonthDays = getDayCountOfMonth(dayjs.year(), dayjs.month());
            const arr = Array.from({length: currentMonthDays}, (v, k) => {
                return {
                    date: this.$dayjs(this.options.date)
                        .add(k, 'day')
                        .format('YYYY-MM-DD'),
                    type: 'current'
                };
            });
            for (let i = 0; i < this.firstDayOfMonth; i++) {
                arr.unshift({
                    date: this.$dayjs(arr[0].date)
                        .subtract(1, 'day')
                        .format('YYYY-MM-DD'),
                    type: 'prev'
                });
            }
            arr.push(
                ...Array.from({length: 7 - (arr.length % 7)}, (v, k) => {
                    return {
                        date: dayjs
                            .add(1, 'month')
                            .add(k, 'day')
                            .format('YYYY-MM-DD'),
                        type: 'next'
                    };
                })
            );
            return this._.chunk(arr, 7);
        },
        //日历数组添加mission
        calendarArrWidthMission() {
            return this.calendarArr.map(row => {
                const startDate = row[0].date;
                const endDate = this.$dayjs(row[row.length - 1].date).add(1, 'day');
                const ret = {};
                for (const key in this.missions) {
                    ret[key] = [];
                    const mission = this.missions[key];
                    mission.forEach(item => {
                        const startTimeIsBetween = this.$dayjs(item._startTime).isBetween(startDate, endDate);
                        const endTimeIsBetween = this.$dayjs(item._endTime).isBetween(startDate, endDate);
                        if (startTimeIsBetween === endTimeIsBetween) {
                            ret[key].push({
                                ...item
                            });
                            return;
                        }
                        if (startTimeIsBetween && !endTimeIsBetween) {
                            ret[key].push({
                                ...item,
                                _endTime: endDate,
                                rightClose: false
                            });
                            return;
                        }
                        if (!startTimeIsBetween && endTimeIsBetween) {
                            ret[key].push({
                                ...item,
                                _startTime: startDate,
                                leftClose: false
                            });
                        }
                    });
                }
                return {
                    days: row,
                    missions: ret
                };
            });
        }
    },
    methods: {
        tooltipHandle(obj) {
            this.$refs.toolTip.toggleShow(obj);
        },
        initialization(options = {}) {
            this.options = {
                date: this.$dayjs().format('YYYY-MM'),
                ...defaultOptions,
                ...options
            };
        },
        setMission(missions = {}) {
            this._missions = missions || {};
            this.update = !this.update;
            console.log(this.calendarArr);
            console.log(this.missions);
        }
    }
};
</script>

<style lang="less" scoped>
.calendar {
    overflow: auto;
}
</style>

