<template>
  <div>
    <el-card class="box-card" :style="{margin: '0 20px 10px 20px'}">
      <div slot="header"
        class="clearfix">
        <span>{{'教育经历'}}</span>
      </div>
      <div class="text item">
        <template>
          <el-table :data="EducationFormData"
            style="width: 100%">
            <el-table-column prop="beginTime"
              :label="'起始时间'"
              :formatter="dateFormat"></el-table-column>
            <el-table-column prop="endTime"
              :label=" '截止时间'"
              :formatter="dateFormat"></el-table-column>
            <el-table-column prop="school"
              :label="'院校'"></el-table-column>
            <el-table-column prop="major"
              :label="'专业'"></el-table-column>
            <el-table-column prop="degree"
              :label="'学历'"></el-table-column>
          </el-table>
        </template>
      </div>
    </el-card>
    <el-card class="box-card" :style="{margin: '0 20px 10px 20px'}">
      <div slot="header"
        class="clearfix">
        <span>{{'入职前经历'}}</span>
      </div>
      <div class="text item">
        <template>
          <el-table :data="WorkExperienceFormData"
            style="width: 100%">
            <el-table-column prop="companyName"
              :label="'公司/单位名称'"></el-table-column>
            <el-table-column prop="beginTime"
              :label=" '起始时间'"
              :formatter="dateFormat"></el-table-column>
            <el-table-column prop="endTime"
              :label="'截止时间'"
              :formatter="dateFormat"></el-table-column>
            <el-table-column prop="position"
              :label="'职务'"></el-table-column>
            <el-table-column v-if="kind === 'fly' "
              prop="planeType"
              :label="'机型'"></el-table-column>
            <el-table-column prop="flyHour"
              :label="'飞行时间'"></el-table-column>
            <el-table-column v-if="kind === 'fly' "
              prop="flyExperienceHour"
              :label="'其中飞行经历时间'"></el-table-column>
            <el-table-column v-if="kind === 'fly' "
              prop="flyNightHour"
              :label="'其中夜间飞行时间'"></el-table-column>
            <el-table-column v-if="kind === 'fly' "
              prop="raiseFallNumber"
              :label="'操作起落次数'"></el-table-column>
            <el-table-column prop="updaterName"
              :label="'更新人'"></el-table-column>
            <el-table-column prop="updateTime"
              :label="'更新时间'"></el-table-column>
          </el-table>
        </template>
      </div>
    </el-card>
    <el-card class="box-card" :style="{margin: '0 20px 0 20px'}">
      <div slot="header"
        class="clearfix">
        <span>{{'成长履历'}}</span>
      </div>
      <div class="text item">
        <template>
          <el-table :data="GrowthHistoryFormData"
            style="width: 100%">
            <el-table-column prop="happenTime"
              :label="'时间'"
              :formatter="dateFormat"></el-table-column>
            <el-table-column prop="recordTypeName"
              :label=" '变动分类'"></el-table-column>
            <el-table-column prop="content"
              :label="'变动内容'"></el-table-column>
            <el-table-column prop="documentNo"
              :label="'变动文号'"></el-table-column>
          </el-table>
        </template>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
    name: 'PersonalExperienceInfo',
    props: {
        id: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'add'
        },
        kind: {
            type: String,
            required: true,
            default: ''
        }
    },
    data() {
        return {
            EducationFormData: [],
            WorkExperienceFormData: [],
            GrowthHistoryFormData: []
        };
    },
    methods: {
        dateFormat(row, column) {
            const date = row[column.property];
            if (date === undefined || date === null) {
                return '';
            }
            return this.$dayjs(date).format('YYYY-MM-DD');
        },
        getEducations() {
            this.$client.getStaffEducateList(this.id).then(data => {
                this.EducationFormData = data || [];
            });
        },
        getWorkExperiences() {
            this.$client.getStaffWorkExperienceList(this.id).then(data => {
                for (let i = 0; i < data.length; i++) {
                    const flyHourH = Math.floor(data[i].flyHour / 60).toString();
                    const flyMinutes = Math.floor(data[i].flyHour % 60).toString();
                    const flyHourM = flyMinutes.length < 2 ? '0' + flyMinutes : flyMinutes;
                    data[i].flyHour = flyHourH + ':' + flyHourM;
                    const flyExperienceHourH = Math.floor(data[i].flyExperienceHour / 60).toString();
                    const flyExperienceMinutes = Math.floor(data[i].flyExperienceHour % 60).toString();
                    const flyExperienceHourM = flyExperienceMinutes.length < 2 ? '0' + flyExperienceMinutes : flyExperienceMinutes;
                    data[i].flyExperienceHour = flyExperienceHourH + ':' + flyExperienceHourM;
                    const flyNightHourH = Math.floor(data[i].flyNightHour / 60).toString();
                    const flyNightMinutes = Math.floor(data[i].flyNightHour % 60).toString();
                    const flyNightHourM = flyNightMinutes.length < 2 ? '0' + flyNightMinutes : flyNightMinutes;
                    data[i].flyNightHour = flyNightHourH + ':' + flyNightHourM;
                }
                this.WorkExperienceFormData = data || [];
            });
        },
        getGrowthRecord() {
            this.$client.getGrowthRecord(this.id).then(data => {
                this.GrowthHistoryFormData = data || [];
            });
        }
    },
    created() {
        //编辑和查看详细时初始化表单
        this.getEducations(this.id);
        this.getWorkExperiences(this.id);
        this.getGrowthRecord(this.id);
    }
};
</script>
<style lang="less" scoped>
/deep/.el-icon-circle-plus {
    cursor: pointer;
    &:hover
    {
        color: #486DD5;
    }
}
</style>

