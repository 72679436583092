//获取某个月份中的天数
export const getDayCountOfMonth = function(year, month) {
    // console.log(year, month);
    // if (month === 3 || month === 5 || month === 8 || month === 10) {
    //     return 30;
    // }

    // if (month === 1) {
    //     if (year % 4 === 0 && year % 100 !== 0 || year % 400 === 0) {
    //         return 29;
    //     }
    //     return 28;
    // }

    // return 31;
    const days = new Date(year, month + 1, 0).getDate();
    return days;
};

//获取月份中的第一天是周几
export const getFirstDayOfMonth = function(date) {
    const temp = new Date(new Date(date).getTime());
    temp.setDate(1);
    return temp.getDay();
};
