<template>
    <div>
        <div class="ui-layout-content--narrow ui-layout-flex ui-layout-flex--column">
            <el-card class="box-card" :style="{margin: '0 0 10px 0'}">
                <div slot="header" class="clearfix">
                    <span>{{'训练计划'}}</span>
                </div>
                <div class="text item">
                    <template>
                        <el-table :data="TrainingPlanData" style="width: 100%">
                            <el-table-column
                                prop="trainName"
                                :label="'训练类别'"
                            ></el-table-column>
                            <el-table-column
                                prop="trainTypeName"
                                :label=" '训练方式'"
                            ></el-table-column>
                            <el-table-column
                                prop="trainingStartDate"
                                :label="'开始日期'"
                                :formatter="dateFormat"
                            ></el-table-column>
                            <el-table-column
                                prop="trainingEndDate"
                                :label="'结束日期'"
                                :formatter="dateFormat"
                            ></el-table-column>
                            <el-table-column
                                prop="trainingTitle"
                                :label="'说明'"
                            ></el-table-column>
                        </el-table>
                    </template>
                </div>
            </el-card>
        </div>
        <div class="ui-layout-content--narrow ui-layout-flex ui-layout-flex--column">
            <el-card class="box-card" :style="{margin: '0 0 10px 0'}">
                <div slot="header" class="clearfix">
                    <span>{{'训练记录'}}</span>
                </div>
                <div class="text item">
                    <template>
                        <el-table :data="TrainingRecordData" style="width: 100%">
                            <el-table-column
                                prop="trainName"
                                :label="'训练类别'"
                            ></el-table-column>
                            <el-table-column
                                prop="trainTypeName"
                                :label=" '训练方式'"
                            ></el-table-column>
                            <el-table-column
                                prop="trainingStartDate"
                                :label="'开始日期'"
                                :formatter="dateFormat"
                            ></el-table-column>
                            <el-table-column
                                prop="trainingEndDate"
                                :label="'结束日期'"
                                :formatter="dateFormat"
                            ></el-table-column>
                            <el-table-column
                                prop="trainPlace"
                                :label="'训练地点'"
                            ></el-table-column>
                            <el-table-column
                                prop="resultCodeName"
                                :label="'结论或成绩'"
                            ></el-table-column>
                            <el-table-column
                                :label="'操作'"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="showDetail(scope.row)"
                                    >{{'查看记录详情'}}</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </div>
            </el-card>
        </div>
        <el-dialog
            width="700px"
            :title="dialog.title"
            v-if="dialog.visible"
            :visible.sync="dialog.visible"
            :modal-append-to-body="true"
            :close-on-click-modal="false"
            :append-to-body="true"
            :show-close="true"
        >
            <personal-training-info-form
                ref="editForm"
                :trainRecordId="dialog.trainRecordId"
                :account="dialog.account"
                :type="dialog.type"
            ></personal-training-info-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModalHandle" size="mini">{{'取消'}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import PersonalTrainingInfoForm from './PersonalTrainingInfoForm';
export default {
    name: 'PersonalTrainingInfo',
    components: {
        PersonalTrainingInfoForm,
    },
    props: {
        //id为特业人员id
        id: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'add',
        },
        kind: {
            type: String,
            required: true,
            default: '',
        },
    },
    data() {
        return {
            TrainingPlanData: [],
            TrainingRecordData: [],
            dialog: {
                trainRecordId: '',
                account: '',
                title: '',
                visible: false,
                type: '',
            },
        };
    },
    methods: {
        closeModalHandle() {
            this.dialog.visible = false;
        },
        dateFormat(row, column) {
            const date = row[column.property];
            if (date === undefined) {
                return '';
            }
            return this.$dayjs(date).format('YYYY-MM-DD');
        },
        showDetail(row) {
            this.dialog = {
                trainRecordId: row.trainingId,
                account: row.userAccount,
                title: '详情',
                visible: true,
                type: 'detail',
            };
        },
        getUserTrainRecord() {
            this.$client.getUserTrainRecord(this.id).then((data) => {
                const trainingPlan = [];
                const trainingRecord = [];
                for (let i = 0; i < data.length; i++) {
                    if (data[i].status === 0) {
                        trainingPlan.push(data[i]);
                    }
                    if (data[i].status === 1) {
                        trainingRecord.push(data[i]);
                    }
                }
                this.TrainingPlanData = trainingPlan;
                this.TrainingRecordData = trainingRecord;
            });
        },
    },
    created() {
        this.getUserTrainRecord(this.id);
    },
};
</script>
<style lang="less" scoped>
/deep/.el-icon-circle-plus {
    cursor: pointer;
    &:hover {
        color: #486dd5;
    }
}
</style>

